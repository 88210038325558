<template>
	<main class="courses-create">
		<h1>Add platform</h1>

		<form @submit.prevent="save">
			<label for="name">Name (for your reference only)</label>
			<input id="name" v-model="name" type="text" autocomplete="off" />

			<label for="platformUrl">Platform URL</label>
			<input id="platformUrl" v-model="platformUrl" type="text" autocomplete="off" />

			<label for="clientId">Client ID (provided by platform)</label>
			<input id="clientId" v-model="clientId" type="text" autocomplete="off" />

			<label for="redirectUri">Redirect URI</label>
			<input id="redirectUri" :value="redirectUri" type="text" autocomplete="off" readonly="readonly" />

			<BaseButton type="submit">
				{{ id ? 'Save' : 'Add' }}
			</BaseButton>
		</form>
	</main>
</template>

<script>
	import Backend from '../../inc/backend';
	import BaseButton from '../../components/BaseButton.vue';
	import {roleGuard} from '../../inc/auth';
	import Bus from '../../inc/bus';

	export default {
		...roleGuard('Courses', 'any', 'teacher'),

		components: {
			BaseButton
		},

		data() {
			return {
				name: '',
				platformUrl: '',
				clientId: ''
			};
		},

		computed: {
			id() {
				return this.$route.params.id || null;
			},
			redirectUri() {
				return document.location.origin + '/api/v1/auth/lti/redirect';
			}
		},

		watch: {
			id() {
				this.fetch();
			}
		},

		created() {
			this.fetch();
		},

		methods: {
			fetch() {
				if(!this.id) {
					return;
				}

				Backend.get(`integrations/platforms/${this.id}`).then(res => {
					const data = res.data;

					this.name = data.name;
					this.platformUrl = data.issuer.issuer;
					this.clientId = data.clientId;
				});
			},

			save() {
				const data = {
					name: this.name,
					platformUrl: this.platformUrl,
					clientId: this.clientId
				};

				if(this.id) {
					Backend.put(`integrations/platforms/${this.id}`, data).then(() => {
						Bus.emit('success', 'Saved!');
					});
				}
				else {
					Backend.post('integrations/platforms', data).then(() => {
						Bus.emit('success', 'Created!');
					});
				}
			}
		}
	};
</script>